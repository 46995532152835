
<template>
	<div class="noticeListStyle">
		<div class="">
			<div class="" style="text-align: center;">
				<p style="font-size: 30px; color: #333333;">
					{{ $t("Bulletin Board") }}&nbsp;&nbsp;{{ $t("Detail") }}
				</p>
			</div>
			<div class="table_style">
        <!-- 公告标题 -->
				<el-row style="padding-bottom: 10px;margin-bottom: 20px;border-bottom: solid 1px #e6e6e6;">
            <el-col :span="24">
                <div class="grid-content bg-purple-dark">
                    <h3 v-if="lang=='zh-CN'" class="noticeTitel" style="text-align: center;">{{ noticeData.shot_title }}</h3> 
                    <h3 v-else class="noticeTitel" style="text-align: center;">{{ noticeData.shot_title_en }}</h3> 
                    <span>{{ new Date(noticeData.createTime).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit',hour:'2-digit', minute:'2-digit', second:'2-digit'}).replace(/\//g,'-')}}</span>
                </div>
            </el-col>
        </el-row>

        <!-- 公告内容 -->
        <el-row>
            <el-col :span="24">
                <pre class="grid-content bg-purple-dark post" v-if="lang=='zh-CN'" v-html="noticeData.content"></pre>
                <pre class="grid-content bg-purple-dark post" v-else v-html="noticeData.content_en"></pre>
            </el-col>
        </el-row>
			</div>
		</div>
	</div>
</template>

<script>
import noticeDetail from "../js/noticeDetail";

export default noticeDetail;
  </script>

<style lang="scss" scoped>
.noticeListStyle {
    margin: 15px 0 0 15px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    &_inner {
        width: 1218px;

        &_title {
            width: 1218px;
            height: 68px;
        }
    }
}

.noticeItem{
    padding: 10px 0;
    border-bottom: solid 1px #e6e6e6;
}

.noticeTitel{
  font-size: 20px;
    // white-space:nowrap;
    // overflow:hidden;
    // text-overflow:ellipsis;
    // width: 70%;
}

.table_style {
    width: 1218px;
    // height: 68px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
<style lang="scss">
.footer .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #218da0; //修改后的背景图颜色
    color: #fff;
}

//修改新闻详情内容样式
.post{
  white-space: pre-wrap !important;
  word-break: break-word !important;
}
.post p {
  margin-bottom: 0px !important;
//   font-size: 20px !important;
}
pre{
  padding: 0 !important;
  font-family: auto !important;
}
.post h1, .post h2, .post h3 {
  font-size: 20px !important;
}
</style>

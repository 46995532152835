
import notice from "@/api/web/home/notice";
export default {
  components: {},
  data() {
    return {
        noticeData:"",
        id:0,
        lang: "",

    };
  },
  watch: {
    
  },
  created() {
     this.getNoticeDetail()
     this.getLang()
  },

  methods: {
   // 获取公告栏
   getNoticeDetail(){
      var query = this.$route.query;
      this.id = query.id;
      notice.getDetail(this.id).then((res) => {
        this.noticeData=res.data.data?res.data.data:"";
      })
   },
   // 获取当前的语言环境
   getLang() {
      // if (this.$i18n.locale == "zh-CN") {
      // 	this.lang = this.$i18n.locale
      // } else {
      // }
      this.lang = this.$i18n.locale;
   },

  },
};
